















































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    ORegistrationFeeGroupInfo: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-group-info.vue"
      ),
    ORegistrationFeeGroupSummary: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-group-summary.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
    }>({
      stepper: 1,
      data: {
        name: null,
        englishName: null,
        status: false,
        additionalInformation: "",
        englishAdditionalInformation: "",
      },
    });

    const dataSummary = computed(() => ({
      data: model.data,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({ step: 1, error: "Podaj nazwę grupy." });

        return errors;
      }),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        order: model.data.order,
        name: model.data.name,
        englishName: model.data.englishName,
        state: model.data.status,
        additionalInformation: model.data.additionalInformation || null,
        englishAdditionalInformation:
          model.data.englishAdditionalInformation || null,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/registration-fee/group`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          model.stepper = 1;
          model.data = {
            name: null,
            englishName: null,
            status: Boolean,
            additionalInformation: null,
            englishAdditionalInformation: null,
          };
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
